import { getCookie } from '@/utils/cookies.js';

export const specialAppList = [
    // 蓝悦开发环境
    {
        appId: 'appwjxwhmzv7002',
        appJoinLink: 'https://work.weixin.qq.com/join/GjWpUmjg_YhgsWuxbRyZ0w/hb_share_mng_contacts',
        appName: '蓝悦软件'
    },
    // 准现网
    {
        appId: 'appwwjjgdne4725',
        appJoinLink: 'https://work.weixin.qq.com/join/GjWpUmjg_YhgsWuxbRyZ0w/hb_share_mng_contacts',
        appName: '小鹅通合作伙伴'
    },
    // 小鹅合作伙伴
    {
        appId: 'app9aw6gubm1891',
        appJoinLink: 'https://work.weixin.qq.com/join/GjWpUmjg_YhgsWuxbRyZ0w/hb_share_mng_contacts',
        appName: '小鹅通合作伙伴'
    },
    // 小摩托
    {
        appId: 'appww6jb0sz9243',
        appJoinLink: 'https://work.weixin.qq.com/join/GjWpUmjg_YhgsWuxbRyZ0w/hb_share_mng_contacts',
        appName: '小鹅通合作伙伴'
    },
    // 小鹅通
    {
        appId: 'appwwf63kst7850',
        appJoinLink: 'https://work.weixin.qq.com/join/YshD-FwICmRgvRrcZJeOZA/hb_share_mng_mjoin',
        appName: '小鹅通'
    }
];

export const getCurrentAppId = () => {
    const currentAppId = (getCookie('with_app_id') || '').toLowerCase();
    return currentAppId;
};

export const getSpecialAppInfo = (appId = '') => {
    return specialAppList.find((item) => item.appId.toLowerCase() === appId.toLowerCase());
};
