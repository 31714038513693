<template>
    <div class="container-invite-posters">
        <div class="container-packet">
            <div class="header">
                <div class="title">{{ shareAppName }}</div>
                <div class="sub-title">给您发来一封邀请</div>
            </div>
            <div v-if="shareUser" class="content">
                {{ `${shareAppName}的` }}
                <span class="name">{{ shareUser }}</span>
                邀请您成为推广员
            </div>
            <div class="content" v-else>{{ `${shareAppName}邀请您成为推广员` }}</div>
            <button class="btn-join" @click="joinCorp">立即加入</button>
        </div>
        <div class="footer">
            <div class="name">
                <img class="logo" src="@/assets/images/logo_xiaoetong.png" alt="" />
                小鹅通提供技术支持
            </div>
            <div class="website">www.xiaoe-tech.com</div>
        </div>
        <div v-show="isInviter" class="mask">
            <div class="img-share">
                <img src="@/assets/images/share_tip.png" alt="" />
            </div>
            <div class="tip">
                点击分享
                <br />
                邀请更多推广员加入
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentAppId, getSpecialAppInfo } from '@/utils/specialApp';

export default {
    data() {
        return {
            isInviter: true,
            shareUser: '',
            shareAppId: '',
            shareAppName: '',
            shareAppJoinLink: '',
            currentAppId: getCurrentAppId(),
            isDistributor: false
        };
    },
    computed: {
        // 邀请推广员的企业与当前账户企业一致
        isSameCorp() {
            return this.currentAppId && this.currentAppId === this.shareAppId;
        }
    },
    methods: {
        isOnlyInWeiXin() {
            const ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger' && ua.match(/wxwork/i) != 'wxwork') {
                return true;
            } else {
                return false;
            }
        },
        joinCorp() {
            if (this.isSameCorp) {
                this.$dialog
                    .alert({
                        message: '请联系企微助手管理员，申请成为推广员'
                    })
                    .then(() => {});
            } else {
                window.location.href = this.shareAppJoinLink;
            }
        },
        initShareMessage() {
            if (wx && wx.onMenuShareWechat) {
                const inviteInfoStr = encodeURIComponent(
                    JSON.stringify({
                        is_inviter: false,
                        share_user: this.shareUser,
                        share_app_id: this.shareAppId,
                        share_app_name: this.shareAppName || '小鹅合作伙伴'
                    })
                );
                const link = `${location.origin}/#${this.$route.path}?invite_info=${inviteInfoStr}`;
                const title = `${this.shareAppName}-推广员邀请`;
                const desc = this.shareUser
                    ? `${this.shareAppName}的${this.shareUser}邀请您成为推广员`
                    : `${this.shareAppName}邀请您成为推广员`;
                const imgUrl =
                    'http://wechatapppro-1252524126.file.myqcloud.com/appzpz3qykw5391/image/U2xpY2UgMS5wbmc.png';
                const success = () => {};
                const cancel = () => {};
                const shareConfig = {
                    title,
                    desc,
                    link,
                    imgUrl,
                    success,
                    cancel
                };
                // 分享到微信
                wx.onMenuShareWechat(shareConfig);
                // 转发
                wx.onMenuShareAppMessage(shareConfig);
                // 分享到朋友圈
                wx.onMenuShareTimeline(shareConfig);
            }
        },
        // TODO: 后续要改成全局的，请求后缓存
        fetchUserRoleInfo() {
            return this.$ajax('get_user_role')
                .then((res) => {
                    const { code, data } = res.data;
                    if (code === 0) {
                        return data;
                    } else {
                        this.$toast('获取用户信息失败');
                        throw new Error('获取用户信息失败');
                    }
                })
                .catch(() => {
                    this.$toast('获取用户信息失败');
                    throw new Error('获取用户信息失败');
                });
        }
    },
    async created() {
        const inviteInfo = JSON.parse(decodeURIComponent(this.$route.query.invite_info));
        this.isInviter = inviteInfo.is_inviter;
        this.shareUser = inviteInfo.share_user;
        this.shareAppId = inviteInfo.share_app_id;
        const { appName: shareAppName, appJoinLink: shareAppJoinLink } = getSpecialAppInfo(this.shareAppId);
        this.shareAppName = shareAppName;
        this.shareAppJoinLink = shareAppJoinLink;
        document.title = `${this.shareAppName}邀请`;
        // 给邀请发起状态、已经登录某企业、分享企业和登录的企业一致、是推广员
        if (!this.isInviter && this.isSameCorp) {
            // 已经是推广员
            const data = await this.fetchUserRoleInfo();
            this.isDistributor = data?.is_distributor;
            if (data?.is_distributor) {
                this.$toast('您已是推广员，跳转推广中心');
                this.$router.replace({
                    name: 'staffPromotion'
                });
            }
        }
        this.initShareMessage();
    }
};
</script>

<style lang="scss" scoped>
.container-invite-posters {
    min-height: 100vh;
    background: url('~@/assets/images/invite_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 1.1rem 0;
    box-sizing: border-box;
    .container-packet {
        height: 11rem;
        max-width: 375px;
        background: url('~@/assets/images/invite_packet2.png') no-repeat;
        background-size: cover;
        background-position: center;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .header {
            padding-top: 1.8rem;
            text-align: center;
            .title {
                font-family: PingFangSC-Medium;
                font-size: 0.32rem;
                color: #333333;
                line-height: 0.48rem;
            }
            .sub-title {
                font-family: PingFangSC-Regular;
                font-size: 0.24rem;
                color: #666666;
                margin-top: 0.08rem;
            }
        }
        .content {
            width: 3.86rem;
            font-family: PingFangSC-Medium;
            font-size: 0.48rem;
            color: #353535;
            line-height: 0.64rem;
            margin: 1.76rem auto 0;
            padding-left: 0.38rem;
            position: absolute;
            bottom: 4.5rem;
            word-break: break-all;
            .name {
                color: #ff781f;
            }
        }
        .btn-join {
            width: 4.74rem;
            height: 0.8rem;
            background-image: linear-gradient(180deg, #f5ffba 0%, #ffd014 100%);
            border-radius: 0.48rem;
            margin-top: 5.6rem;
            border: none;
            outline: 0;
            font-size: 0.28rem;
            color: #333333;
            font-family: PingFangSC-Medium;
        }
    }
    .footer {
        font-family: PingFangSC-Regular;
        font-size: 0.2rem;
        color: #fff;
        line-height: 0.28rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .name {
            display: flex;
            align-items: center;
            .logo {
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.04rem;
            }
        }
        .website {
            margin-top: 0.02rem;
        }
    }
    .mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        max-width: 480px;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-share {
            height: 3.61rem;
            width: 100%;
            img {
                width: 4.03rem;
                float: right;
            }
        }
        .tip {
            height: 1.12rem;
            font-family: PingFangSC-Medium;
            font-size: 0.4rem;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            margin: 0.4rem 0 0.48rem;
        }
        .btn-close {
            width: 3.48rem;
            height: 0.8rem;
            background: #ffffff;
            border: 0.02rem solid #ffffff;
            border-radius: 0.48rem;
        }
    }
}
</style>
